import { dateFilter, numberFilter, textFilter, Comparator } from 'react-bootstrap-table2-filter';

const today = new Date();
const thisMonth = today.getMonth() + 1;

const defaultDateFilter1 = dateFilter({
  defaultValue: {
    date: new Date(today.getFullYear() - 1, thisMonth <= 6 ? 5 : 11, 31),
    comparator: Comparator.GT
  }
});

const defaultSmallCol = {
  style: {
    maxWidth: '135px',
    minWidth: '135px'
  },
  headerStyle: {
    maxWidth: '135px',
    minWidth: '135px',
    textAlign: 'center'
  },
  display: false
};

const defaultMediumCol = {
  style: {
    maxWidth: '210px',
    minWidth: '210px',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden'
  },
  headerStyle: {
    maxWidth: '210px',
    minWidth: '210px',
    textAlign: 'center'
  }
};

const commonMap = [
  {
    dataField: 'customer',
    text: 'Customer',
    filter: textFilter(),
    sort: true,
    editable: false,
    ...defaultMediumCol,
    display: true,
    filterType: 'text'
  },
  {
    dataField: 'year',
    text: 'Year',
    filter: numberFilter(),
    sort: true,
    editable: false,
    ...defaultMediumCol,
    display: true
  },
  {
    dataField: 'facility',
    text: 'Facility',
    filter: textFilter(),
    sort: true,
    editable: false,
    ...defaultMediumCol,
    display: true,
    filterType: 'text'
  },
  {
    dataField: 'activity',
    text: 'Activity',
    filter: textFilter(),
    sort: true,
    editable: false,
    ...defaultMediumCol,
    display: true,
    filterType: 'text'
  },
  {
    dataField: 'sampleDate',
    text: 'Sample Date',
    filter: defaultDateFilter1,
    sort: true,
    editable: false,
    ...defaultSmallCol,
    display: true
  },
  {
    dataField: 'shortFileName',
    text: 'File Name',
    filter: textFilter(),
    sort: true,
    editable: false,
    ...defaultMediumCol,
    display: true,
    filterType: 'text'
  },
  {
    dataField: 'flags',
    text: 'Flags',
    filter: textFilter(),
    sort: true,
    editable: false,
    ...defaultMediumCol,
    display: true,
    filterType: 'text'
  }
];

const columnMapping = {
  default: commonMap
};

for (const cm of Object.keys(columnMapping)) {
  for (const c of columnMapping[cm]) {
    if (!c.headerStyle && !c.style) {
      c.headerStyle = {
        minWidth: '135px'
      };
    }
  }
}

const printColumnsTemplate = [
  { text: 'customer', dataField: 'customer' },
  { text: 'year', dataField: 'year' },
  { text: 'facility', dataField: 'facility' },
  { text: 'activity', dataField: 'activity' },
  { text: 'fileName', dataField: 'fileName' },
  { text: 'parsedDateTime', dataField: 'parsedDateTime' },
  { text: 'location', dataField: 'location' },
  { text: 'product', dataField: 'product' },
  { text: 'notes', dataField: 'notes' },
  { text: 'lab', dataField: 'lab' },
  { text: 'sampleDate', dataField: 'sampleDate' },
  { text: 'coaNum', dataField: 'coaNum' },
  { text: 'sampleId', dataField: 'sampleId' },
  { text: 'iButane', dataField: 'iButane' },
  { text: 'iButaneTestMethod', dataField: 'iButaneTestMethod' },
  { text: 'iButaneUnits', dataField: 'iButaneUnits' },
  { text: 'nButane', dataField: 'nButane' },
  { text: 'nButaneTestMethod', dataField: 'nButaneTestMethod' },
  { text: 'nButaneUnits', dataField: 'nButaneUnits' },
  { text: 'inButane', dataField: 'inButane' },
  { text: 'butaneTotal', dataField: 'butaneTotal' },
  { text: 'butaneTotalTestMethod', dataField: 'butaneTotalTestMethod' },
  { text: 'butaneTotalUnits', dataField: 'butaneTotalUnits' },
  { text: 'olefins', dataField: 'olefins' },
  { text: 'olefinsTestMethod', dataField: 'olefinsTestMethod' },
  { text: 'olefinsUnits', dataField: 'olefinsUnits' },
  { text: 'aromatics', dataField: 'aromatics' },
  { text: 'aromaticsTestMethod', dataField: 'aromaticsTestMethod' },
  { text: 'aromaticsUnits', dataField: 'aromaticsUnits' },
  { text: 'benzene', dataField: 'benzene' },
  { text: 'benzeneTestMethod', dataField: 'benzeneTestMethod' },
  { text: 'benzeneUnits', dataField: 'benzeneUnits' },
  { text: 'sulfur', dataField: 'sulfur' },
  { text: 'sulfurTestMethod', dataField: 'sulfurTestMethod' },
  { text: 'sulfurUnits', dataField: 'sulfurUnits' },
  { text: 'rvp', dataField: 'rvp' },
  { text: 'rvpTestMethod', dataField: 'rvpTestMethod' },
  { text: 'rvpUnits', dataField: 'rvpUnits' },
  { text: 'flags', dataField: 'flags' },
  { text: 'originalDocumentURL', dataField: 'originalDocumentURL' }
];

const printColumns = printColumnsTemplate.map((c) => {
  return {
    ...c,
    key: c.dataField
  };
});

export { columnMapping, printColumns, defaultSmallCol };
