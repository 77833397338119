/* eslint-disable max-classes-per-file */
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Alert, Button, FormControl, TextField } from '@mui/material';
import * as ROUTES from '../../constants/routes';
import { useFirebase } from '../Firebase/context';
import { useHistory } from 'react-router-dom';

const SignInPage = () => (
  <div>
    <h1>SignIn </h1>
    <SignInForm />
    <Link to={ROUTES.PASSWORD_FORGET}>Forgot Password?</Link>
  </div>
);

const INITIAL_STATE = {
  email: '',
  password: '',
  error: null
};

const ERROR_CODE_ACCOUNT_EXISTS = 'auth/account-exists-with-different-credential';

const ERROR_MSG_ACCOUNT_EXISTS = `
  An account with an E-Mail address to
  this social account already exists. Try to login from
  this account instead and associate your social accounts on
  your personal account page.
`;

const SignInForm = () => {
  const history = useHistory();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);
  const firebase = useFirebase();

  const onSubmit = (event) => {
    if (!password && firebase.hasAzureADProvider()) {
      firebase
        .hasAzureADProvider()
        .then(() => {
          setEmail('');
          setPassword('');
          setError(null);
          history.push(ROUTES.REPORTS);
        })
        .catch((err) => {
          setError(err);
        });
    }

    firebase
      .doSignInWithEmailAndPassword(email, password)
      .then(() => {
        setEmail('');
        setPassword('');
        setError(null);
        history.push(ROUTES.REPORTS);
      })
      .catch((err) => {
        setError(err);
      });

    event.preventDefault();
  };

  const isInvalid = (password === '' || email === '') && !firebase.hasAzureADProvider();

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
      {!firebase.hasAzureADProvider() && (
        <>
          <FormControl fullWidth>
            <TextField
              name="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              label="Email Address"
              type="email"
            />
          </FormControl>
          <FormControl fullWidth>
            <TextField
              name="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              type="password"
              label="Password"
            />
          </FormControl>
        </>
      )}

      {error && <Alert severity="error">{error.message}</Alert>}

      <Button variant="contained" color="primary" disabled={isInvalid} onClick={onSubmit}>
        Sign In
      </Button>
    </div>
  );
};

const SignInGoogle = (props) => {
  const [error, setError] = useState(null);
  const { history } = props;
  const firebase = useFirebase();

  const onSubmit = (event) => {
    firebase
      .doSignInWithGoogle()
      .then((socialAuthUser) => {
        // Create a user in your Firebase Realtime Database too
        return firebase.user(socialAuthUser.user.uid).set({
          username: socialAuthUser.user.displayName,
          email: socialAuthUser.user.email,
          roles: {}
        });
      })
      .then(() => {
        setError(null);
        history.push(ROUTES.REPORTS);
      })
      .catch((errorIn) => {
        const error = errorIn;
        if (error.code === ERROR_CODE_ACCOUNT_EXISTS) {
          error.message = ERROR_MSG_ACCOUNT_EXISTS;
        }

        setError(error);
      });

    event.preventDefault();
  };

  return (
    <form onSubmit={this.onSubmit}>
      <button type="submit">Sign In with Google</button>

      {error && <p>{error.message}</p>}
    </form>
  );
};

const SignInFacebook = (props) => {
  const [error, setError] = useState(null);
  const { history } = props;
  const firebase = useFirebase();

  const onSubmit = (event) => {
    firebase
      .doSignInWithFacebook()
      .then((socialAuthUser) => {
        // Create a user in your Firebase Realtime Database too
        return firebase.user(socialAuthUser.user.uid).set({
          username: socialAuthUser.additionalUserInfo.profile.name,
          email: socialAuthUser.additionalUserInfo.profile.email,
          roles: {}
        });
      })
      .then(() => {
        setError(null);
        history.push(ROUTES.REPORTS);
      })
      .catch((errorIn) => {
        const error = errorIn;
        if (error.code === ERROR_CODE_ACCOUNT_EXISTS) {
          error.message = ERROR_MSG_ACCOUNT_EXISTS;
        }

        setError(error);
      });

    event.preventDefault();
  };

  return (
    <form onSubmit={onSubmit}>
      <button type="submit">Sign In with Facebook</button>

      {error && <p>{error.message}</p>}
    </form>
  );
};

const SignInTwitter = (props) => {
  const [error, setError] = useState(null);
  const { history } = props;
  const firebase = useFirebase();

  const onSubmit = (event) => {
    const { firebase, history } = this.props;
    firebase
      .doSignInWithTwitter()
      .then((socialAuthUser) => {
        // Create a user in your Firebase Realtime Database too
        return firebase.user(socialAuthUser.user.uid).set({
          username: socialAuthUser.additionalUserInfo.profile.name,
          email: socialAuthUser.additionalUserInfo.profile.email,
          roles: {}
        });
      })
      .then(() => {
        setError(null);
        history.push(ROUTES.REPORTS);
      })
      .catch((errorIn) => {
        const error = errorIn;
        if (error.code === ERROR_CODE_ACCOUNT_EXISTS) {
          error.message = ERROR_MSG_ACCOUNT_EXISTS;
        }

        setError(error);
      });

    event.preventDefault();
  };

  return (
    <form onSubmit={onSubmit}>
      <button type="submit">Sign In with Twitter</button>

      {error && <p>{error.message}</p>}
    </form>
  );
};

export default SignInPage;

export { SignInForm, SignInGoogle, SignInFacebook, SignInTwitter };
