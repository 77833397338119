import { dateFilter, numberFilter, textFilter, Comparator } from 'react-bootstrap-table2-filter';

const today = new Date();
const thisMonth = today.getMonth() + 1;

const defaultDateFilter1 = dateFilter({
  defaultValue: {
    date: new Date(today.getFullYear() - 1, thisMonth <= 6 ? 5 : 11, 31),
    comparator: Comparator.GT
  }
});

const defaultSmallCol = {
  style: {
    maxWidth: '135px',
    minWidth: '135px'
  },
  headerStyle: {
    maxWidth: '135px',
    minWidth: '135px',
    textAlign: 'center'
  },
  display: false
};

const defaultMediumCol = {
  style: {
    maxWidth: '210px',
    minWidth: '210px',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden'
  },
  headerStyle: {
    maxWidth: '210px',
    minWidth: '210px',
    textAlign: 'center'
  }
};

const defaultLargeCol = {
  style: {
    maxWidth: '300px',
    minWidth: '300px'
  },
  headerStyle: {
    maxWidth: '300px',
    minWidth: '300px',
    textAlign: 'center'
  }
};

const commonMap = [
  {
    dataField: 'productionDate',
    text: 'Date',
    filter: defaultDateFilter1,
    sort: true,
    editable: false,
    ...defaultSmallCol,
    display: true
  },
  {
    dataField: 'location',
    text: 'Location',
    filter: textFilter(),
    sort: true,
    editable: false,
    ...defaultMediumCol,
    display: true,
    filterType: 'text'
  },
  {
    dataField: 'volumeType',
    text: 'Volume Type',
    filter: textFilter(),
    sort: true,
    editable: false,
    ...defaultMediumCol,
    display: true,
    filterType: 'text'
  },
  {
    dataField: 'batchVolume',
    text: 'Volume',
    filter: numberFilter(),
    sort: true,
    editable: false,
    ...defaultMediumCol,
    display: true
  },
  {
    dataField: 'productType',
    text: 'Product',
    filter: textFilter(),
    sort: true,
    editable: false,
    ...defaultSmallCol,
    display: true,
    filterType: 'text'
  },
  {
    dataField: 'oxygenateNumber',
    text: 'Oxygenate',
    filter: numberFilter(),
    sort: true,
    editable: false,
    ...defaultMediumCol,
    display: true
  },
  {
    dataField: 'sulfurPCGV',
    text: 'Sulfur PG',
    filter: numberFilter(),
    sort: true,
    editable: false,
    ...defaultMediumCol,
    display: true
  },
  {
    dataField: 'sulfurACV',
    text: 'Sulfur ACV',
    filter: numberFilter(),
    sort: true,
    editable: false,
    ...defaultMediumCol,
    display: true
  },
  {
    dataField: 'benzene',
    text: 'Benzene',
    filter: numberFilter(),
    sort: true,
    editable: false,
    ...defaultMediumCol,
    display: true
  },
  {
    dataField: 'rvp',
    text: 'RVP',
    filter: numberFilter(),
    sort: true,
    editable: false,
    ...defaultMediumCol,
    display: true
  },
  {
    dataField: 'benzeneDeficit',
    text: 'Benzene Def',
    filter: numberFilter(),
    sort: true,
    editable: false,
    ...defaultMediumCol,
    display: true
  },
  {
    dataField: 'sulfurDeficit',
    text: 'Sulfur Deficit',
    filter: numberFilter(),
    sort: true,
    editable: false,
    ...defaultMediumCol,
    display: true
  }
];

const columnMapping = {
  bgb: commonMap,
  cbabw: commonMap,
  cbabc: commonMap,
  cbs: commonMap,
  dm: commonMap,
  kc: commonMap,
  pcg: commonMap,
  tgp: commonMap
};

for (const cm of Object.keys(columnMapping)) {
  for (const c of columnMapping[cm]) {
    if (!c.headerStyle && !c.style) {
      c.headerStyle = {
        minWidth: '135px'
      };
    }
  }
}

export { columnMapping, defaultSmallCol, defaultMediumCol, defaultLargeCol };
