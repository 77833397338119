import {
  List,
  ListItem,
  ListItemText,
  Collapse,
  IconButton,
  TableCell,
  TableRow
} from '@mui/material';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

const CollapsibleRow = ({ row, columns }) => {
  const [open, setOpen] = useState(false);

  const columnsToDisplay = columns.filter((value) => value.display);
  const columnsInCollapse = columns.filter((value) => !value.display);
  return (
    <>
      <TableRow>
        <TableCell>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>

        {columnsToDisplay.map((c) => (
          <TableCell key={`${c.text}-${row[c.dataField]}`}>
            {c.formatter ? c.formatter(row[c.dataField]) : row[c.dataField]}
          </TableCell>
        ))}
      </TableRow>
      <TableRow>
        <TableCell
          style={{ paddingBottom: 0, paddingTop: 0 }}
          colSpan={columnsToDisplay.length + 1}
        >
          <Collapse in={open} timeout="auto" unmountOnExit>
            <List>
              <div
                style={{
                  display: 'flex',
                  flexFlow: 'column wrap',
                  gap: '0 30px',
                  backgroundColor: '#EEE',
                  height: 500, // set the height limit to your liking
                  overflow: 'auto'
                }}
              >
                {columnsInCollapse
                  .sort((a, b) => {
                    // sort alphabetically by text field
                    return a.text.toLowerCase().localeCompare(b.text.toLowerCase());
                  })
                  .map((c) => (
                    <ListItem
                      style={{ width: '190px' }}
                      key={`list-item-${c.text}-${row[c.dataField]}`}
                    >
                      <ListItemText primary={c.text} secondary={row[c.dataField] || 'No data'} />
                    </ListItem>
                  ))}
              </div>
            </List>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

CollapsibleRow.propTypes = {
  row: PropTypes.object.isRequired,
  columns: PropTypes.array.isRequired
};

export default CollapsibleRow;
