import React, { useEffect, useState, createContext, useContext } from 'react';
import Firebase from '../components/Firebase/firebase';
import { useFirebase } from '../components/Firebase/context';

export const AuthContext = createContext(null);

const AuthProvider = ({ children }) => {
  const firebase = useFirebase();
  const [authUser, setAuthUser] = useState(null);

  useEffect(() => {
    if (firebase) {
      setAuthUser(JSON.parse(localStorage.getItem('authUser')));
      const listener = firebase.onAuthUserListener(
        (authUser) => {
          // eslint-disable-next-line no-undef
          localStorage.setItem('authUser', JSON.stringify(authUser));
          setAuthUser(authUser);
        },
        () => {
          // eslint-disable-next-line no-undef
          localStorage.removeItem('authUser');
          setAuthUser(null);
        }
      );
      return () => {
        listener();
      };
    }
  }, [firebase]);

  return <AuthContext.Provider value={{ authUser, firebase }}>{children} </AuthContext.Provider>;
};

export const useAuth = () => {
  return useContext(AuthContext);
};

export default AuthProvider;
