import React, { Component, useState } from 'react';
import PropTypes from 'prop-types';
import { Alert, Button, FormControl, TextField } from '@mui/material';

import { withFirebase } from '../Firebase';
import { useFirebase } from '../Firebase/context';

const PasswordForgetPage = ({ error, setError }) => (
  <div>
    <h1>Password Forget</h1>
    <PasswordForgetFormBase error={error} setError={setError} />
  </div>
);

const PasswordForgetFormBase = ({ error, setError }) => {
  const firebase = useFirebase();
  const [email, setEmail] = useState('');
  function onSubmit(event) {
    firebase
      .doPasswordReset(email)
      .then(() => {
        setEmail('');
        setError(null);
      })
      .catch((err) => {
        setError(err);
      });

    event.preventDefault();
  }

  function onChange(event) {
    setEmail(event.target.value);
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
      <FormControl>
        <TextField
          name="email"
          value={email}
          onChange={onChange}
          type="text"
          label="Email Address"
        />
      </FormControl>
      {error && <Alert severity="error">{error.message}</Alert>}
      <Button disabled={email === ''} onClick={onSubmit} color="primary" variant="contained">
        Reset My Password
      </Button>
    </div>
  );
};

export default PasswordForgetPage;

export { PasswordForgetFormBase };
