import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { LinkContainer } from 'react-router-bootstrap';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Tooltip
} from '@mui/material';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import AutoGraphIcon from '@mui/icons-material/AutoGraph';
import ReportIcon from '@mui/icons-material/Report';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import MenuIcon from '@mui/icons-material/Menu';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import LocalGasStationIcon from '@mui/icons-material/LocalGasStation';

import magellanLogo from '../../images/magellan-horizontal.jpg';
import genericLogo from '../../images/generic_logo.png';
import SignOutDrawer from './signOutDrawer';
import SignOutButton from '../SignOut';
import * as ROUTES from '../../constants/routes';
import * as ROLES from '../../constants/roles';
import { useAuth } from '../../hook/AuthProvider';

const Navigation = () => {
  const { authUser } = useAuth();
  console.log(`Nav ${JSON.stringify(authUser, null, 2)}`);
  if (authUser) return <NavigationAuth authUser={authUser} />;
  return null;
};

const notMmp2 = (authUser) => authUser.data !== 'mmp2';

const isMagellan = (authUser) => String(authUser?.data).startsWith('mmp');

const isAdmin = (authUser) => !!authUser.roles[ROLES.ADMIN];

const navigationItems = [
  { label: 'Data Selection', icon: <ReportIcon />, route: ROUTES.REPORTS, display: () => true }
];

const NavigationAuth = ({ authUser }) => {
  const [openDrawer, setOpenDrawer] = useState(false);

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '0.5rem', position: 'fixed' }}>
      <IconButton color="default" onClick={() => setOpenDrawer(true)}>
        <MenuIcon />
      </IconButton>
      <Drawer anchor="left" open={openDrawer} onClose={() => setOpenDrawer(false)}>
        <List>
          <ListItem>
            <ListItemAvatar>
              <img
                alt="Logo"
                src={isMagellan(authUser) ? magellanLogo : genericLogo}
                style={{ width: '16vw' }}
              />
            </ListItemAvatar>
          </ListItem>
          {navigationItems
            .filter((item) => item.display(authUser))
            .map((item) => (
              <LinkContainer
                key={item.route}
                to={item.route}
                active={0}
                onClick={() => setOpenDrawer(false)}
              >
                <ListItem>
                  <ListItemButton>
                    <ListItemIcon>{item.icon}</ListItemIcon>
                    <ListItemText primary={item.label} />
                  </ListItemButton>
                </ListItem>
              </LinkContainer>
            ))}
          <SignOutDrawer />
        </List>
      </Drawer>
      {navigationItems
        .filter((item) => item.display(authUser))
        .map((item) => (
          <LinkContainer key={item.route} to={item.route} href={item.href} active={0}>
            <Tooltip title={item.label} placement="right">
              <IconButton> {item.icon}</IconButton>
            </Tooltip>
          </LinkContainer>
        ))}

      <SignOutButton />
    </div>
  );
};

export default Navigation;
