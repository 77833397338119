import { Drawer } from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';

export const RightPanel = ({ open, setOpen, children }) => (
  <Drawer anchor="right" open={open} onClose={() => setOpen(false)}>
    <div
      style={{
        minWidth: '20vw',
        padding: '0.5rem',
        gap: '1rem',
        display: 'flex',
        flexDirection: 'column'
      }}
    >
      {children}
    </div>
  </Drawer>
);

RightPanel.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired
};

export default RightPanel;
