/* eslint-disable no-console */
import app from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/database';
import 'firebase/compat/storage';
import {
  hashQueryKey,
  QueryClient,
  QueryClientProvider as QueryClientProviderBase,
  useQuery
} from 'react-query';
import 'firebase/compat/firestore';
import { getDoc } from 'firebase/firestore';

function getConfiguration(hostname) {
  console.log(`hostname: ${hostname}`);
  if (hostname.match(/tmc\./)) {
    return {
      apiKey: 'AIzaSyBcV7IaHwW4_OaBxmWbnIP1N7vEO0uJo5g',
      authDomain: 'fuelfolder-tmc.firebaseapp.com',
      projectId: 'fuelfolder-tmc'
    };
  }
  return {
    apiKey: 'AIzaSyAB6mNOPaUXacH7oXgHyxjzLChf34vt9Wo',
    authDomain: 'fuelfolder.firebaseapp.com',
    projectId: 'fuelfolder'
  };
}

class Firebase {
  constructor() {
    const appConfig = getConfiguration(window.location.hostname);
    app.initializeApp(appConfig);

    /* Helper */

    this.serverValue = app.database.ServerValue;
    this.emailAuthProvider = app.auth.EmailAuthProvider;

    /* Firebase APIs */

    this.auth = app.auth();
    this.db = app.firestore();
    this.storage = app.storage();
    this.client = new QueryClient();

    /* Social Sign In Method Provider */

    this.googleProvider = new app.auth.GoogleAuthProvider();
    this.facebookProvider = new app.auth.FacebookAuthProvider();
    this.twitterProvider = new app.auth.TwitterAuthProvider();
    this.azureADProvider = appConfig.useAzureAD
      ? new app.auth.OAuthProvider('microsoft.com')
      : null;

    this.dataPath = 'mmp';
  }

  hasAzureADProvider = () => this.azureADProvider !== null;

  // *** Auth API ***

  doCreateUserWithEmailAndPassword = (email, password) =>
    this.auth.createUserWithEmailAndPassword(email, password);

  doSignInWithEmailAndPassword = (email, password) =>
    this.auth.signInWithEmailAndPassword(email, password);

  doSignInWithGoogle = () => this.auth.signInWithPopup(this.googleProvider);

  doSignInWithFacebook = () => this.auth.signInWithPopup(this.facebookProvider);

  doSignInWithTwitter = () => this.auth.signInWithPopup(this.twitterProvider);

  doSignInWithAzureAD = () => this.auth.signInWithPopup(this.azureADProvider);

  doSignOut = () => this.auth.signOut();

  doPasswordReset = (email) => this.auth.sendPasswordResetEmail(email);

  doSendEmailVerification = () =>
    this.auth.currentUser.sendEmailVerification({
      url: process.env.REACT_APP_CONFIRMATION_EMAIL_REDIRECT
    });

  doPasswordUpdate = (password) => this.auth.currentUser.updatePassword(password);

  // *** Merge Auth and DB User API *** //

  onAuthUserListener = (next, fallback) =>
    this.auth.onAuthStateChanged(async (authUserIn) => {
      let authUser = authUserIn;
      if (authUser) {
        const dbUserFromDb = await this.user(authUser.uid);
        const dbUser = dbUserFromDb || {};
        if (!dbUser.roles) {
          dbUser.roles = {};
        }
        authUser = {
          uid: authUser.uid,
          email: authUser.email,
          emailVerified: authUser.emailVerified,
          providerData: authUser.providerData,
          ...dbUser
        };

        if (authUser.data) {
          this.dataPath = authUser.data;
        }

        next(authUser);
      } else {
        fallback();
      }
    });

  // *** Storage ***
  storageRef = (rid) => this.storage.ref(rid);

  // *** User API ***

  user = async (uid) => {
    const doc = await this.db.collection('users').doc(uid).get();
    if (!doc.exists) {
      return null;
    }
    return doc.data();
  };

  data = (collection) => {
    return (
      this.db
        .collection(collection)
        // .where('capital', '==', true)
        .get()
        .then((querySnapshot) => {
          const rows = [];
          querySnapshot.forEach((doc) => {
            rows.push(doc.data());
          });
          return rows;
        })
        .catch((error) => {
          console.log('Error getting data: ', error);
        })
    );
  };

  updateEditData = (collection, id, data) => {
    return this.db.collection(collection).doc(`${id}`).set(data);
  };

  // ** clean values **
  cleanName = (name) => name.replace(/[.#$/[\]]/g, '');
}

export default Firebase;
