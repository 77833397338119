import React from 'react';
import Image from 'react-bootstrap/Image';
import { BrowserRouter as Switch, Route, Redirect } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import { createTheme, ThemeProvider } from '@mui/material';

import Navigation from '../Navigation';
import SignInPage from '../SignIn';
import PasswordForgetPage from '../PasswordForget';
import SortedPage from '../Sorted';
import ReportPage from '../Report';

import * as ROUTES from '../../constants/routes';
import magellanLogo from '../../images/magellan-horizontal.jpg';
import genericLogo from '../../images/generic_logo.png';
import ProtectedLayout from '../../layout/ProtectedLayout';

const App = () => {
  const theme = createTheme({
    palette: {
      primary: {
        light: '#33935E',
        main: '#007936',
        dark: '#005425',
        contrastText: '#fff'
      },
      secondary: {
        light: '#8A8C80',
        main: '#6D7061',
        dark: '#4C4E43',
        contrastText: '#fff'
      }
    }
  });

  return (
    <Switch>
      <ThemeProvider theme={theme}>
        <Route
          render={({ location }) => {
            const showNavigation = true;
            console.log(`location: ${location}`);
            return showNavigation && <Navigation />;
          }}
        />

        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '1.25rem'
          }}
        >
          <Container style={{ marginTop: '1.25rem' }}>
            <Route path={ROUTES.SIGN_IN} component={SignInPage} />
            <ProtectedLayout>
              <Route path={ROUTES.PASSWORD_FORGET} component={PasswordForgetPage} />
              <Route path={ROUTES.REFINED_DATA} component={SortedPage} />
              <Route path={ROUTES.REPORTS} component={ReportPage} />
              <Route exact path={ROUTES.LANDING}>
                {<Redirect to={ROUTES.REPORTS} />}
              </Route>
            </ProtectedLayout>
          </Container>
        </div>
      </ThemeProvider>
    </Switch>
  );
};
export default App;
