import React from 'react';
import { ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import LogoutIcon from '@mui/icons-material/Logout';

import { useFirebase } from '../Firebase/context';

const SignOutButton = () => {
  const firebase = useFirebase();
  return (
    <ListItem>
      <ListItemButton onClick={firebase.doSignOut}>
        <ListItemIcon>
          <LogoutIcon />
        </ListItemIcon>
        <ListItemText primary="Sign out" />
      </ListItemButton>
    </ListItem>
  );
};

export default SignOutButton;
