import React, { useEffect } from 'react';
import * as ROUTES from '../constants/routes';
import { useFirebase } from '../components/Firebase/context';
import { useHistory } from 'react-router-dom';

export const ProtectedLayout = ({ children }) => {
  const history = useHistory();
  const firebase = useFirebase();
  useEffect(() => {
    if (firebase) {
      const listener = firebase.onAuthUserListener(
        (authUser) => {
          if (!authUser) {
            history.push(ROUTES.SIGN_IN);
          }
        },
        () => {
          history.push(ROUTES.SIGN_IN);
        }
      );
      return () => {
        listener();
      };
    }
  }, [firebase]);

  return <div>{children}</div>;
};

export default ProtectedLayout;
