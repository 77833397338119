import React from 'react';
import PropTypes from 'prop-types';

import PaginationTable from '../Common/PaginationTable/paginationTable';
import CollapsibleRow from './collapsibleRow';

const RefinedDataTable = ({ rows, columns }) => (
  <PaginationTable
    rows={rows}
    columns={columns}
    rowComponent={(row) => <CollapsibleRow row={row} columns={columns} key={Math.random()} />}
    columnFilter={(c) => c.display}
    collapsible
  />
);

RefinedDataTable.propTypes = {
  rows: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired
};

export default RefinedDataTable;
