const prefix = '';
export const LANDING = `${prefix}/`;
export const SIGN_IN = `${prefix}/signin`;
export const QMS = `${prefix}/qms`;
export const REFINED_DATA = `${prefix}/refined-data`;
export const AGGREGATIONS = `${prefix}/aggregations`;
export const CHANGE_LOG = `${prefix}/change-log`;
export const FACILITY_REVIEW = `${prefix}/facility-review`;
export const REPORTS = `${prefix}/reports`;
export const METRICS = `${prefix}/metrics`;
export const ACCOUNT = `${prefix}/account`;
export const PASSWORD_FORGET = `${prefix}/pw-forget`;
export const ADMIN = `${prefix}/admin`;
export const ADMIN_DETAILS = `${prefix}/admin/:id`;
export const LIBRARY = `${prefix}/library`;
export const RINS = `${prefix}/rins`;
export const RINS_REPORT = `${prefix}/report/rins`;
