/* eslint-disable react/jsx-no-bind */
import React, { Component, useEffect, useState } from 'react';
import _ from 'lodash';
import { Type } from 'react-bootstrap-table2-editor';
import { dateFilter, textFilter } from 'react-bootstrap-table2-filter';
import { Button, FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { columnMapping, defaultMediumCol, defaultSmallCol } from './Columns';
import Spinner from '../Spinner';
import RefinedDataTable from './refinedDataTable';
import ButtonTabs from '../Common/ButtonTabs/buttonTabs';
import { useFirebase } from '../Firebase/context';

const fileList = [
  {
    id: 'bgb',
    name: 'BGB'
  },
  {
    id: 'cbabc',
    name: 'CBA-BC'
  },
  {
    id: 'cbabw',
    name: 'CBA-BW'
  },
  {
    id: 'cbs',
    name: 'CBS'
  },
  {
    id: 'dm',
    name: 'Des Moines'
  },
  {
    id: 'kc',
    name: 'Kansas City'
  },
  {
    id: 'pcg',
    name: 'PCG'
  },
  {
    id: 'tgp',
    name: 'TGP'
  }
];

let selectedSort = fileList[0];

const tabDisplay = [
  { title: 'Sorted', sortTypes: ['A', 'F', 'M'] },
  { title: 'Missing', sortTypes: ['M'] },
  { title: 'Deleted', sortTypes: ['D'] },
  { title: 'Complete', sortTypes: ['A'] }
];

const SortedPage = () => {
  const [loading, setLoading] = useState(false);
  const [rows, setRows] = useState([]);
  const [columns, setColumns] = useState([]);
  const [keyField, setKeyField] = useState('index');
  const [selectedTab, setSelectedTab] = useState(0);
  const [exceptionRows, setExceptionRows] = useState(null);
  const firebase = useFirebase();
  useEffect(() => {
    loadSelectedFile();
  }, []);

  useEffect(() => {
    loadSelectedFile(true);
  }, [selectedTab]);

  const loadSelectedFile = async (force = false) => {
    const { sortTypes } = tabDisplay[selectedTab];
    if (force || !rows || rows.length < 1) {
      setLoading(true);
      const [snapshot, notesSnapshot, statusSnapshot, analyticalSnapshot] = await Promise.all([
        firebase.sort(selectedSort.id).once('value'),
        firebase.notes(`sort-${selectedSort.id}`).once('value'),
        firebase.notes(`status-${selectedSort.id}`).once('value'),
        firebase.notes(`analytical-${selectedSort.id}`).once('value')
      ]);

      // need to double up dates for two-way date filters
      const rowsMain = snapshot.val();
      const header = Object.keys(rowsMain[0]);

      let columns = [];
      if (columnMapping[selectedSort.id]) {
        columns = _.cloneDeep(columnMapping[selectedSort.id]);
      } else {
        // build header based upon actual contents
        header
          .filter((h) => !['tank_line', 'remarks'].includes(h))
          .forEach((h) => {
            columns.push({
              dataField: h,
              text: h,
              filter: h.endsWith('date') || h.startsWith('date') ? dateFilter() : textFilter(),
              sort: true,
              editable: false,
              ...defaultSmallCol
            });
          });
      }

      if (
        (sortTypes.includes('F') || sortTypes.includes('A')) &&
        !columns.find((c) => c.dataField === 'status')
      ) {
        columns.push({
          dataField: 'status',
          text: 'Status',
          filter: textFilter(),
          sort: true,
          editable: sortTypes.length === 1,
          ...defaultSmallCol,
          editor: {
            type: Type.SELECT,
            editcellstyle: { minWidth: 200 },
            options: [
              {
                value: 'ACTIVE',
                label: 'ACTIVE'
              },
              {
                value: 'EXCEPTION',
                label: 'EXCEPTION'
              },
              {
                value: 'SILENCE',
                label: 'SILENCE'
              }
            ]
          }
        });
      }
      if (sortTypes.includes('F') && !columns.find((c) => c.dataField === 'followupreason')) {
        columns.push({
          dataField: 'followupreason',
          text: 'Follow-Up Reason',
          filter: textFilter(),
          sort: true,
          editable: false,
          ...defaultMediumCol,
          editcellstyle: { minWidth: 200 }
        });
      }

      if (sortTypes.length > 1 && !columns.find((c) => c.dataField === 'sort')) {
        columns.push({
          dataField: 'sort',
          text: 'Category Code',
          filter: textFilter(),
          sort: true,
          editable: false,
          ...defaultSmallCol
        });
      }

      /*
      if (sortTypes.length === 1 && !columns.find((c) => c.dataField === 'assignanalytical')) {
        columns.push({
          dataField: 'assignanalytical',
          text: 'Assign Analytical',
          filter: textFilter(),
          sort: true,
          ...defaultMediumCol,
          editcellstyle: { minWidth: 200 },
          editor: {
            type: Type.TEXTAREA,
            rows: 2,
            cols: 40
          }
        });
      }
      */

      if (sortTypes.length === 1 && !columns.find((c) => c.dataField === 'customnotes')) {
        columns.push({
          dataField: 'customnotes',
          text: 'Notes',
          filter: textFilter(),
          sort: true,
          ...defaultMediumCol,
          editcellstyle: { minWidth: 200 },
          editor: {
            type: Type.TEXTAREA,
            rows: 2,
            cols: 40
          }
        });
      }

      let notes = notesSnapshot.val();
      if (!notes) {
        notes = {};
      }
      let status = statusSnapshot.val();
      if (!status) {
        status = {};
      }
      let analytical = analyticalSnapshot.val();
      if (!analytical) {
        analytical = {};
      }
      for (const row of rowsMain) {
        row.customnotes = notes[row.sampleid] || '';
        row.status = status[row.sampleid] || 'ACTIVE';
        row.assignanalytical = analytical[row.sampleid] || '';
      }

      // set up rows:
      let moreRows = rowsMain.filter((r) => sortTypes.includes(r.sort));
      let exceptionRows = [];

      // handle follow-up designations:
      if (sortTypes.length === 1 && sortTypes.includes('A')) {
        moreRows = moreRows.concat(
          rowsMain.filter((r) => r.sort === 'F' && r.status === 'SILENCE')
        );
      }
      if (sortTypes.length === 1 && sortTypes.includes('F')) {
        exceptionRows = moreRows.filter((r) => r.status === 'EXCEPTION');
        moreRows = moreRows.filter((r) => r.status === 'ACTIVE');
      }

      header.forEach((h) => {
        const foundHeader = columns.find((c) => c.dataField === h);
        if (!foundHeader) {
          columns.push({
            dataField: h,
            text: h,
            filter: h.endsWith('date') || h.startsWith('date') ? dateFilter() : textFilter(),
            sort: true,
            editable: false,
            ...defaultSmallCol
          });
        }
      });
      setRows(moreRows);
      setColumns(columns);
      setExceptionRows(exceptionRows);
      setLoading(false);
    }
  };

  const changeSelectedFile = (event) => {
    selectedSort = JSON.parse(JSON.stringify(fileList.find((fl) => fl.id === event.target.value)));
    setRows([]);
    loadSelectedFile(true);
  };

  const saveNotes = (oldValue, newValue, rowIn, column) => {
    const row = rowIn;
    // TODO: check how to implement it
    const writeObj = {};
    writeObj[row.sampleid] = newValue;
    if (column.dataField === 'assignanalytical') {
      firebase.saveNote(`analytical-${selectedSort.id}`, writeObj);
    }
    if (column.dataField === 'customnotes') {
      firebase.saveNote(`sort-${selectedSort.id}`, writeObj);
    }
    if (column.dataField === 'status') {
      firebase.saveNote(`status-${selectedSort.id}`, writeObj);
      if (newValue !== 'ACTIVE') {
        setLoading(true);
        row.customnotes =
          row.customnotes && row.customnotes !== ''
            ? `${row.customnotes} [${newValue}]`
            : `[${newValue}]`;
        const notesObj = {};
        notesObj[row.sampleid] = row.customnotes;
        firebase.saveNote(`sort-${selectedSort.id}`, notesObj);
        setLoading(false);
      }
    }
  };

  const refreshClick = () => {
    setLoading(true);
    setTimeout(() => {
      loadSelectedFile(true);
    }, 200);
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '0.75rem' }}>
      <h1>Refined Data</h1>
      <div style={{ display: 'flex', gap: '0.75rem' }}>
        <FormControl fullWidth>
          <InputLabel id="select-file-label">File</InputLabel>
          <Select
            labelId="select-file-label"
            value={selectedSort.id}
            label="File"
            onChange={changeSelectedFile}
            disabled={loading}
          >
            {fileList.map((fl) => {
              return (
                <MenuItem key={`menuItem-file-${fl.id}`} value={fl.id}>
                  {fl.name}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
        <Button color="primary" variant="contained" onClick={refreshClick}>
          Refresh
        </Button>
      </div>
      <ButtonTabs
        tabDisplay={tabDisplay}
        setSelectedTab={(value) => setSelectedTab(value)}
        selectedTab={selectedTab}
      />

      {loading && <Spinner loading={loading} />}
      {!loading && rows && Array.isArray(rows) && (
        <div>
          {rows && Array.isArray(rows) && rows.length > 0 && (
            <RefinedDataTable keyField={keyField} rows={rows} columns={columns} />
          )}
        </div>
      )}

      {!loading && exceptionRows && exceptionRows.length > 0 && (
        <RefinedDataTable keyField={keyField} exceptionRows={exceptionRows} columns={columns} />
      )}
    </div>
  );
};

export default SortedPage;
