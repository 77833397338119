import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { PostHogProvider } from 'posthog-js/react';

import './custom.scss';
import './index.css';

import * as serviceWorker from './serviceWorker';

import store from './store';
import App from './components/App';
import Firebase, { FirebaseContext } from './components/Firebase';
import AuthProvider from './hook/AuthProvider';

const options = {
  api_host: 'https://app.posthog.com'
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <FirebaseContext.Provider value={new Firebase()}>
      <AuthProvider>
        <PostHogProvider apiKey="phc_Mayf7aOXraliOgtdsxgOSxGAJLlvZ1hlAVMXaqM9FUt" options={options}>
          <App />
        </PostHogProvider>
      </AuthProvider>
    </FirebaseContext.Provider>
  </Provider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
