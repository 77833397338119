/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/jsx-curly-newline */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react/jsx-filename-extension */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  FormControl,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  TextField
} from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import moment from 'moment';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';

import FilterDrawer from '../Filters/filterDrawer';

function compareDates({ value, minDate, maxDate }) {
  if (!minDate && !maxDate) {
    return true;
  }

  if (!maxDate) {
    return moment(value).isSameOrAfter(minDate, 'day');
  }

  if (!minDate) {
    return moment(value).isSameOrBefore(maxDate, 'day');
  }

  return moment(value).isBetween(minDate, maxDate, 'day', '[]');
}

class Filter {
  constructor({
    field,
    label,
    type,
    value = type === 'date' ? { minDate: null, maxDate: null } : ''
  }) {
    this.field = field;
    this.value = value;
    this.label = label;
    this.type = type;
  }

  check(f) {
    if (this.type === 'date') {
      return compareDates({
        value: f[this.field],
        maxDate: this.value.maxDate,
        minDate: this.value.minDate
      });
    }
    return f[this.field]?.toLowerCase().includes(this.value.toLowerCase());
  }

  updateValue = (value) =>
    new Filter({ field: this.field, value, label: this.label, type: this.type });
}

const PaginationTable = ({
  rows,
  columns,
  printColumns,
  rowComponent,
  columnFilter = () => true,
  collapsible = false
}) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [filteredRows, setFilteredRows] = useState(rows);
  const [paginatedRows, setPaginatedRows] = useState(filteredRows.slice(0, 10));
  const [order, setOrder] = useState(0);
  const [fieldOrder, setFieldOrder] = useState('');

  const filters = columns
    .filter((c) => c.filterType)
    .map((c) => new Filter({ field: c.dataField, label: c.text, type: c.filterType }));
  const [filterValues, setFilterValues] = useState(filters);

  const downloadCSV = ({ columns: csvColumns, rows: csvRows }) => {
    console.log(`downloadCSV with ${csvColumns.length} columns and ${csvRows.length} rows`);
    const csv = `"${csvColumns.map((c) => c.text).join('","')}"\n${csvRows
      .map((row) => `"${csvColumns.map((c) => row[c.dataField]).join('","')}"`)
      .join('\n')}`;

    const csvData = new Blob([csv], { type: 'text/csv' });
    const link = URL.createObjectURL(csvData);

    // eslint-disable-next-line no-undef
    window.open(link);
  };

  const icons = { 1: <ArrowDropUpIcon />, '-1': <ArrowDropDownIcon /> };

  const removeCommas = (s) => (Number.isNaN(s) ? s?.replaceAll(/,/g, '') : s);

  useEffect(() => {
    setPage(0);
    setPaginatedRows(rows.slice(0, rowsPerPage));
  }, [rows, rowsPerPage]);

  useEffect(() => {
    setFilteredRows(rows.filter((r) => filterValues.every((f) => !f.value || f.check(r))));
  }, [filterValues, rows, rows.length]);

  useEffect(() => {
    const sortRows = (a, b) => {
      if (Number.isNaN(removeCommas(a[fieldOrder])))
        return (a[fieldOrder] > b[fieldOrder] ? 1 : -1) * order;
      return (
        (parseFloat(removeCommas(a[fieldOrder])) > parseFloat(removeCommas(b[fieldOrder]))
          ? 1
          : -1) * order
      );
    };
    setPaginatedRows(
      filteredRows.sort(sortRows).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
    );
  }, [page, rowsPerPage, order, fieldOrder, filteredRows]);

  const clickHeader = (c) => {
    if (fieldOrder === c.dataField) {
      setOrder(order * -1);
    } else {
      setFieldOrder(c.dataField);
      setOrder(1);
    }
  };

  return (
    <>
      <Box sx={{ boxShadow: 3 }}>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow style={{ backgroundColor: '#BBB' }}>
                {collapsible && <TableCell />}
                {columns.filter(columnFilter).map((c, i) => (
                  <TableCell
                    key={c.text + i}
                    onClick={() => {
                      clickHeader(c);
                    }}
                  >
                    <strong>{c.text}</strong> {c.dataField === fieldOrder && icons[order]}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>{paginatedRows.map(rowComponent)}</TableBody>
            <TableFooter>
              <TableRow>
                <TableCell colSpan={2}>
                  <Button
                    variant="contained"
                    onClick={() => downloadCSV({ columns: printColumns || columns, rows })}
                    sx={{
                      backgroundColor: '#FF5722',
                      color: 'white',
                      '&:hover': { backgroundColor: '#E64A19' }
                    }}
                  >
                    Export CSV
                  </Button>
                </TableCell>
                <TablePagination
                  count={filteredRows.length}
                  page={page}
                  rowsPerPage={rowsPerPage}
                  onPageChange={(e, newPage) => setPage(newPage)}
                  onRowsPerPageChange={(e) => setRowsPerPage(e.target.value)}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </Box>
      {filterValues.length > 0 && (
        <FilterDrawer>
          {filterValues.map((f, i) => {
            if (f.type === 'date') {
              return (
                <React.Fragment key={i}>
                  <FormControl fullWidth>
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                      <DatePicker
                        label={`${f.label} from`}
                        onChange={(value) =>
                          setFilterValues(
                            filterValues.map((f2) => {
                              if (f2.field === f.field) {
                                return f2.updateValue({ ...f.value, minDate: value });
                              }
                              return f2;
                            })
                          )
                        }
                        value={f.value.minDate}
                        renderInput={(params) => <TextField {...params} />}
                        style={{ width: '100%' }}
                        maxDate={f.value.maxDate}
                      />
                    </LocalizationProvider>
                  </FormControl>
                  <FormControl fullWidth>
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                      <DatePicker
                        label={`${f.label} to`}
                        value={f.value.maxDate}
                        onChange={(value) =>
                          setFilterValues(
                            filterValues.map((f2) => {
                              if (f2.field === f.field) {
                                return f2.updateValue({ ...f.value, maxDate: value });
                              }
                              return f2;
                            })
                          )
                        }
                        style={{ width: '100%' }}
                        renderInput={(params) => <TextField {...params} />}
                        minDate={f.value.minDate}
                      />
                    </LocalizationProvider>
                  </FormControl>
                </React.Fragment>
              );
            }

            return (
              <TextField
                label={f.label}
                key={f.field}
                value={f.value}
                onChange={(e) => {
                  setFilterValues(
                    filterValues.map((f2) => {
                      if (f2.field === f.field) {
                        return f2.updateValue(e.target.value);
                      }
                      return f2;
                    })
                  );
                }}
              />
            );
          })}
        </FilterDrawer>
      )}
    </>
  );
};

PaginationTable.propTypes = {
  rows: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  columns: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  rowComponent: PropTypes.func.isRequired,
  columnFilter: PropTypes.func,
  collapsible: PropTypes.bool,
  printColumns: PropTypes.arrayOf(PropTypes.shape({}))
};

PaginationTable.defaultProps = {
  columnFilter: () => true,
  collapsible: false,
  printColumns: null
};

export default PaginationTable;
