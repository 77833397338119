import React from 'react';
import { IconButton, Tooltip } from '@mui/material';
import LogoutIcon from '@mui/icons-material/Logout';
import { useFirebase } from '../Firebase/context';

const SignOutButton = () => {
  const firebase = useFirebase();
  return (
    <IconButton onClick={firebase.doSignOut}>
      <Tooltip title="Sign Out" placement="right">
        <LogoutIcon />
      </Tooltip>
    </IconButton>
  );
};

export default SignOutButton;
