/* eslint-disable react/jsx-curly-newline */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, TextField } from '@mui/material';

const TransactionForm = ({ updateRow, initialState }) => {
  const [newRow, setNewRow] = useState(initialState);
  const topFields = ['customer', 'year', 'facility', 'activity'];
  const noShowFields = [
    'fileName',
    'parsedDateTime',
    'index',
    'id',
    'originalDocumentURL',
    'flags'
  ];
  const showFields = Object.keys(newRow)
    .filter((field) => !noShowFields.includes(field))
    .filter((field) => !topFields.includes(field))
    .sort();

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
      {topFields.map((field) => (
        <TextField
          key={field}
          label={field}
          value={newRow[field] || ''}
          disabled={true}
          onChange={(e) => {
            setNewRow({ ...newRow, [field]: e.target.value });
          }}
        />
      ))}
      {showFields.map((field) => (
        <TextField
          key={field}
          label={field}
          value={newRow[field] || ''}
          onChange={(e) => {
            setNewRow({ ...newRow, [field]: e.target.value });
          }}
        />
      ))}
      <Button
        onClick={() => {
          updateRow(newRow);
        }}
      >
        Save
      </Button>
    </div>
  );
};

TransactionForm.propTypes = {
  updateRow: PropTypes.func.isRequired,
  initialState: PropTypes.object
};

TransactionForm.defaultProps = {
  initialState: {}
};

export default TransactionForm;
