import FilterAltIcon from '@mui/icons-material/FilterAlt';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { RightPanel } from '../Panels/rightPanel';

const FilterDrawer = ({ children }) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <RightPanel open={open} setOpen={setOpen}>
        {children}
      </RightPanel>
    </>
  );
};

FilterDrawer.propTypes = {
  children: PropTypes.node.isRequired,
  icon: PropTypes.node
};

FilterDrawer.defaultProps = {
  icon: <FilterAltIcon />
};

export default FilterDrawer;
